@import "../styles.lib.scss";

body:has(.ssw-modal-overlay.visible) {
    overflow: hidden;
}

.ssw-modal-overlay {
    z-index: 1060;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $search-modal-overlay-background;

    &.visible {
        display: block;
    }

    &.hidden {
        display: none;
    }

    .ssw-modal {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        background: $color-e-s-white;
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 35px;

        .close-btn {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            margin: 20px 35px 0 0;
            border-radius: 50px;
            border-style: none;
            background: transparent;
            padding: 0;

            svg.close-x {
                width: 45px;
                height: 45px;
                display: block;

                path {
                    fill: $lu21-color-e-s-all-black;
                }

                path.circleoutline {
                    fill: $lu21-color-e-s-mid-grey;
                    transition: fill 0.3s ease-in-out;
                }

                &:hover path.circleoutline {
                    fill: $lu21-color-e-s-all-black;
                    transition: fill 0.3s ease-in-out;
                }
            }
        }

        .ssw-modal-header {
            color: $lu21-color-e-s-all-black;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: 0.5px;
            margin: 4px 0 6px 0;
        }

        hr.ssw-modal-line {
            border-top: 1px solid $lu21-color-e-s-mid-grey;
            width: 100%;
            margin: 15px 0;
        }

        .ssw-modal-body {
            margin-top: 10px;

            .text {
                line-height: 12px;
                font-size: 13px;
                letter-spacing: 0.3px;
                color: $lu21-color-e-s-all-black;
            }
        }

        .confirm-btn {
            border: none;
            cursor: pointer;
            color: $color-e-s-white;
            float: none;
            background-color: $lu21-color-e-s-red;
            height: 30px;
            border-radius: 30px;
            padding: 0 20px;
            font-size: 12px;
            line-height: 22px;
            font-weight: 500;
            letter-spacing: 0.3px;
            min-width: unset;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background-color: $lu21-color-e-s-light-red;
                transition: background-color 0.3s ease-in-out;
            }

            &:focus-visible {
                outline: 1px auto Highlight; // firefox
                outline: -webkit-focus-ring-color auto 1px; // chromium
            }
        }
    }
}
