﻿$color-e-s-black: #1e1e1e;
$color-e-s-white: #ffffff;
$color-e-s-grey2: #c5c5c5;
$category-color-workwear: #D20A0F;
$es-default-text-color: #696969;
$es-grey-border: #C8C8C8;
$es-grey-medium: #787878;

$search-modal-overlay-background: rgba(0, 0, 0, 0.7);
$search-grid-grey: #f0f0f0;
$search-grid-hover-grey: #969696;
$search-grid-hover-grey-background: #ebebeb;
$search-grid-border-radius: 25px;
$search-grid-side-padding: 22px;
$search-grid-suggestions-transition-duration: 0.200s;
$search-grid-border-transition-duration: calc($search-grid-suggestions-transition-duration / 3);
$search-grid-transition-timing: linear;
$search-backdrop-grey: #2e2e2e;

$lu21-color-e-s-all-black: #000000;
$lu21-color-e-s-red: #E30613;
$lu21-color-e-s-light-red: #FF0818;
$lu21-color-e-s-dark-grey: #999999;
$lu21-color-e-s-mid-grey: #D3D4D4;
$lu21-color-e-s-light-grey: #F0F1F1;
