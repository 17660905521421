﻿@import "../styles.lib.scss";

.scope-ssw {
    .searchbox.wide {
        $margin-width: 5px;
        width: 310px;

        .search {
            width: 300px;
            height: 50px;
            margin: 0;
            background-color: transparent;

            form {
                position: absolute;
                width: 290px;
                margin: $margin-width;

                .ssw-input {
                    width: 290px;
                    text-transform: uppercase;
                    height: 41px;
                    padding-left: 15px;
                    background-color: transparent;
                }

                .ssw-button {
                    cursor: pointer;
                    background-color: transparent;

                    svg {
                        fill: $lu21-color-e-s-all-black;
                    }
                }

                &:hover {
                    .ssw-button,
                    .ssw-input,
                    .ssw-input::placeholder {
                        color: $lu21-color-e-s-dark-grey;
                    }
                }

                .ssw-input:focus {
                    @include searchbox-wide-focus-input;
                }
            }
        }

        &.focus .search form .ssw-input {
            text-transform: none;
            @include searchbox-wide-focus-input;
        }
    }
}
