.scope-ssw {
    .searchbox.flyout {
        @media only screen and (max-width: 767px) {
            .feature-promotion {
                margin: 15px 15px 0;
            }
        }

        @media only screen and (max-width: 349px) {
            .feature-promotion {
                margin: 10px 0 0;

                .promotion-logo-container {
                    display: none;
                }

                .promotion-headline-container {
                    width: 100%;
                }
            }
        }
    }
}

// include previous styles (without prices and images)
.scope-ssw {
    .searchbox.flyout.legacy .feature-promotion {
      width: 100%;
      min-height: 140px;
    }
  
    @media only screen and (max-width: 767px) {
      .searchbox.flyout.legacy {
        .feature-promotion {
          width: calc(100% - 30px);
          margin: 15px;
        }
  
        .suggestions.multiple-lists .feature-promotion {
          width: calc(100% - 47px);
        }
      }
    }
  
    @media only screen and (max-width: 349px) {
      .searchbox.flyout.legacy {
        .feature-promotion,
        .suggestions.multiple-lists .feature-promotion {
          width: 100%;
          margin: 10px 0;
    
          .promotion-logo-container {
            display: none;
          }
      
          .promotion-headline-container {
            width: 90%;
          }
        }
      }
    }
  }