@import "../styles.lib.scss";

.scope-ssw {
    .searchbox {
        .suggestions-container {
            &.multiple-columns .feature-promotion.stretchy {
                margin-top: 10px;
            }

            .feature-promotion {
                line-height: 1.42;
                font-family: Roboto, Arial, Helvetica, sans-serif;
                color: $lu21-color-e-s-all-black;
                position: relative;
                padding: 13px;
                padding-left: 18px;
                background-color: $lu21-color-e-s-light-grey;
                display: inline-block;

                &.fixed {
                    width: 290px;
                    min-height: 165px;
                }

                &.is-touched .promotion-button-container .promotion-button,
                &.is-selected .promotion-button-container .promotion-button {
                    border: 1px solid $lu21-color-e-s-all-black;
                }

                .promotion-logo-container {
                    float: right;

                    .promotion-logo {
                        width: 90px;
                        height: 90px;
                    }
                }

                .promotion-headline-container {
                    float: left;
                    width: 62%;

                    .promotion-headline-inner-container {
                        font-size: 16px;
                        font-family: Augusta, Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        text-transform: uppercase;

                        .promotion-headline {
                            letter-spacing: 0.5px;
                        }
                    }
                }

                .promotion-button-container {
                    float: left;
                    height: auto;
                    margin-top: 8px;

                    .promotion-button {
                        padding: 0 10px 0 20px;
                        display: inline-block;
                        cursor: pointer;
                        background-color: transparent;
                        border: 1px solid $lu21-color-e-s-mid-grey;
                        text-decoration: none;
                        font-weight: 500;
                        font-size: 14px;
                        color: $lu21-color-e-s-all-black;
                        border-radius: 50px;
                        transition: border-color 0.3s;
                    }

                    .promotion-button-text {
                        cursor: pointer;
                        color: $lu21-color-e-s-all-black;
                        display: flex;
                        align-items: center;
                        height: 38px;

                        .promotion-button-icon {
                            margin-top: 2px;
                            padding-left: 15px;

                            svg {
                                width: 6px;
                                height: 12px;
                            }
                        }

                        .promotion-button-content {
                            min-width: 140px;
                        }
                    }
                }
            }
        }
    }
}

// include previous styles (without prices and images)
.scope-ssw {
    .searchbox.legacy {
        .suggestions {
            .feature-promotion {
                line-height: 1.42;
                font-family: Roboto, Arial, Helvetica, sans-serif;
                color: $lu21-color-e-s-all-black;
                margin-top: 10px;
                width: 314px;
                min-height: 165px;
                position: relative;
                padding: 15px;
                padding-left: 20px;
                background-color: $lu21-color-e-s-light-grey;
                display: inline-block;

                .promotion-logo-container {
                    float: right;
                    margin-bottom: 15px;

                    .promotion-logo {
                        width: 90px;
                        height: 90px;
                    }
                }

                .promotion-headline-container {
                    float: left;
                    width: 62%;

                    .promotion-headline-inner-container {
                        font-size: 16px;
                        margin-bottom: 25px;
                        font-family: Augusta, Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        text-transform: uppercase;

                        .promotion-headline {
                            letter-spacing: 0.5px;
                        }
                    }
                }

                .promotion-button-container {
                    float: left;
                    height: auto;

                    .promotion-button {
                        padding: 0 10px 0 20px;
                        display: inline-block;
                        cursor: pointer;
                        background-color: transparent;
                        border: 1px solid $lu21-color-e-s-mid-grey;
                        text-decoration: none;
                        font-weight: 500;
                        font-size: 14px;
                        color: $lu21-color-e-s-all-black;
                        border-radius: 50px;
                        transition: border-color 0.3s;

                        &:hover {
                            border: 1px solid $lu21-color-e-s-all-black;
                        }
                    }

                    .promotion-button-text {
                        cursor: pointer;
                        color: $lu21-color-e-s-all-black;
                        display: flex;
                        align-items: center;
                        height: 38px;

                        .promotion-button-icon {
                            margin-top: 2px;
                            padding-left: 15px;

                            svg {
                                width: 6px;
                                height: 12px;
                            }
                        }

                        .promotion-button-content {
                            min-width: 140px;
                        }
                    }
                }
            }
        }
    }
}

@import "promotion.flyout.scss";
@import "promotion.grid.scss";
