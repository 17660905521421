.scope-ssw .mkt-headr-icon-wrapper {
    .ssw-flyout-icon {
        svg.search-2021-icon {
            display: inline;
        }
    }
}

.mobileshop .scope-ssw .mkt-headr-icon-wrapper .ssw-flyout-icon.has-focus-mouse {
    outline: 0;
}
