﻿@mixin suggestion-wide-layout {
    position: fixed;

    ul {
        $padding-width: 8px;

        li {
            &:last-child {
                margin-bottom: 2 * $padding-width;
            }

            &.suggestions-text,
            &.top-search-query-text,
            a {
                padding-bottom: $padding-width;
            }

            a {
                padding-top: $padding-width;
            }

            &.suggestions-text:first-child,
            &.top-search-query-text:first-child {
                padding-top: $padding-width;
            }

            &.suggestions-text,
            &.top-search-query-text {
                padding-top: $padding-width * 3;
            }
        }

        span.suggestions-text {
            margin-top: $padding-width;
            padding-top: $padding-width;
        }
    }
}

@mixin suggestion-flyout-layout {
    ul {
        li {
            border-top: 1px solid $lu21-color-e-s-mid-grey;

            &.top-search-query-item:last-child {
                border-bottom: 1px solid $lu21-color-e-s-mid-grey;
            }

            &.suggestions-text,
            &.top-search-query-text,
            a {
                padding-top: 17px;
                padding-bottom: 17px;
            }
        }
    }
}

@mixin list-item-base-layout {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    &.suggestions-text,
    &.top-search-query-text {
        color: $lu21-color-e-s-dark-grey;
        cursor: default;
        text-transform: uppercase;
        font-weight: bold;
    }

    a {
        color: inherit;
        text-decoration: inherit;
        text-overflow: ellipsis;
        display: inherit;
        overflow: hidden;

        strong {
            font-weight: bold;
        }
    }

    &.is-touched:not(.suggestions-text, .top-search-query-text),
    &.is-selected:not(.suggestions-text, .top-search-query-text) {
        background-color: $lu21-color-e-s-light-grey;
    }
}

@mixin article-column-base-layout {
    span.suggestions-text {
        display: block;
        color: $lu21-color-e-s-dark-grey;
        cursor: default;
        text-transform: uppercase;
        font-weight: bold;
    }

    .article-container {
        div.suggestion-item {
            padding-top: 10px;
            padding-bottom: 10px;

            a {
                display: flex;
                flex-direction: row;
                height: 100px;
                padding: 0;
                color: $lu21-color-e-s-all-black;
                text-decoration: none;

                .image {
                    display: flex;
                    height: 100px;
                    width: 100px;
                    min-width: 100px;
                    margin-right: 7px;
                    background-color: $lu21-color-e-s-light-grey;

                    img {
                        margin: auto;
                        mix-blend-mode: multiply;
                        width: 90px;
                    }
                }

                .article-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-left: 5px;
                    padding-right: 8px;
                    padding-top: 3px;
                    padding-bottom: 3px;

                    .article-name {
                        flex-grow: 1;
                        max-height: 46px;
                        overflow: hidden;
                        width: 175px;
                        overflow-wrap: break-word;
                    }

                    .article-variants {
                        font-size: 10px;
                        color: $lu21-color-e-s-dark-grey;
                    }
                }
            }

            &.is-touched:not(.suggestions-text) a,
            &.is-selected:not(.suggestions-text) a {
                color: inherit;
                background-color: $lu21-color-e-s-light-grey;
                text-decoration: none;
            }
        }

        .result-button-container {
            order: 8;
            height: 120px;
            display: flex;
            margin-left: auto;
            margin-right: auto;

            &.is-touched a.show-results-button,
            &.is-selected a.show-results-button {
                border: 1px solid $lu21-color-e-s-all-black;
            }

            a.show-results-button {
                margin: auto;
                padding: 0 15px 0 20px;
                cursor: pointer;
                border: 1px solid $lu21-color-e-s-mid-grey;
                text-decoration: none;
                font-weight: 500;
                font-size: 13px;
                color: $lu21-color-e-s-all-black;
                border-radius: 50px;
                transition: border-color 0.3s;

                .result-button-text {
                    cursor: pointer;
                    color: $lu21-color-e-s-all-black;
                    display: flex;
                    align-items: center;
                    height: 42px;
                }

                .result-button-content {
                    white-space: pre;
                }

                .result-button-icon {
                    margin-top: 4px;
                    padding-left: 10px;

                    svg {
                        width: 6px;
                        height: 12px;
                    }
                }
            }
        }
    }
}

@mixin narrow-grid-suggestions-merged-column-layout {
    .suggestions-container.merged-column {
        ul.suggestions-list-container {
            margin: 0;
            padding: 0;

            li {
                @include list-item-base-layout;
                line-height: 15px;

                &:last-child {
                    border-bottom: none;
                }
            }

            .article-column-container {
                @include article-column-base-layout;

                span.suggestions-text {
                    margin-top: 0;
                }

                .article-container {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;

                    div.suggestion-item {
                        padding-top: 5px;
                        padding-bottom: 5px;

                        a {
                            height: 100px;
                        }
                    }
                }
            }
        }
    }
}

@mixin compact-article-layout(
    $item-padding-left,
    $a-padding-left,
    $a-padding-right,
    $a-height,
    $parent-horizontal-padding
) {
    div.suggestion-item {
        padding: 0 0 0 $item-padding-left;

        a {
            height: $a-height;
            padding: 12px $a-padding-right 12px $a-padding-left;

            .image,
            .article-info .article-price {
                display: none;
            }

            .article-info {
                display: block;
                padding: 0;

                .article-name {
                    display: inline-block;
                    width: calc(
                        100vw - $item-padding-left - $a-padding-left - $a-padding-right -
                            $parent-horizontal-padding
                    );
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-top: 0;
                }
            }
        }

        &.is-touched,
        &.is-selected {
            background-color: $lu21-color-e-s-light-grey;
        }
    }

    div.result-button-container {
        margin-left: 65px;
    }
}

// include previous styles (without prices and images)
@mixin suggestion-wide-medium-small-layout($leftPosition: -20px) {
    position: fixed;
    margin-left: $leftPosition;

    ul {
        $padding-width: 8px;

        li {
            &:first-child {
                border-top: $padding-width solid $color-e-s-white;
            }

            &:last-child {
                border-bottom: 2 * $padding-width solid $color-e-s-white;
            }

            &.suggestions-text,
            &.top-search-query-text,
            a {
                padding-bottom: $padding-width;
            }

            a {
                padding-top: $padding-width;
            }

            &.suggestions-text:first-child,
            &.top-search-query-text:first-child {
                padding-top: $padding-width;
            }

            &.suggestions-text,
            &.top-search-query-text {
                padding-top: $padding-width * 4;
            }
        }
    }
}

@mixin suggestion-flyout-layout-legacy {
    ul {
        li {
            border-top: 1px solid $lu21-color-e-s-mid-grey;

            &:first-child {
                border-top-width: 0;
            }

            &.top-search-query-item:last-child {
                border-bottom: 1px solid $lu21-color-e-s-mid-grey;
            }

            &.suggestions-text,
            &.top-search-query-text,
            a {
                padding-top: 17px;
                padding-bottom: 17px;
            }
        }
    }
}
