﻿@import "../styles.lib.scss";

.scope-ssw {
    .searchbox-grid {
        .new-suggestions {
            width: calc(100% + 2px);
            min-height: 127px;
            margin-left: -1px;
            display: inherit;
            overflow: hidden;
            transition: transform $search-grid-suggestions-transition-duration
                $search-grid-transition-timing 0s;
            transform: scaleY(0);
            transform-origin: top;
            background-color: $color-e-s-white;
            text-align: left;
            position: relative;
            border-left: 1px solid $es-grey-border;
            border-right: 1px solid $es-grey-border;
            border-bottom: 1px solid $es-grey-border;
            border-bottom-left-radius: $search-grid-border-radius;
            border-bottom-right-radius: $search-grid-border-radius;
            z-index: 150; // scroll under mkt-headr, fas_sticky_header and over mkt-footr-scope

            html:not(.desktop):not(.kiosk) & {
                overflow-x: hidden;
                overflow-y: scroll;
            }

            $padding-width: 11px;

            .suggestions-container.multiple-columns
                .article-column-container
                .article-container
                div.suggestion-item.articles {
                border-bottom: 1px solid $lu21-color-e-s-mid-grey;

                &:only-child {
                    border-bottom: 0;
                }
            }

            .suggestions-container.multiple-columns {
                // only for suggestions without TSQs and content links
                &:has(> .article-column-container:only-child),
                &:has(> .feature-promotion):has(> .article-column-container) {
                    div.suggestion-item.articles {
                        // article left of the button
                        &:nth-child(odd):nth-last-child(2) {
                            border-bottom: 0;
                        }
                    }
                }
            }

            .suggestions-container.multiple-columns {
                &:has(> .suggestions-list-container):has(> .article-column-container) {
                    .article-column-container .article-container {
                        width: 290px;

                        > div {
                            padding-left: 0;
                            margin-left: 0;
                        }
                    }
                }

                &:has(> .feature-promotion):has(> .article-column-container) {
                    width: auto;

                    .article-column-container .article-container {
                        max-width: none;
                        justify-content: space-evenly;
                    }
                }

                &:has(> .suggestions-list-container:only-child) {
                    .suggestions-list-container {
                        flex: 1;
                    }
                }

                &:has(> .article-column-container:only-child),
                &:has(> .feature-promotion):has(> .article-column-container) {
                    .article-column-container {
                        flex: 1;

                        .suggestion-item.articles {
                            display: flex;
                            flex: 1;

                            a {
                                flex: 1;
                            }
                        }
                    }
                }

                .article-column-container {
                    &.padded {
                        padding-left: 15px;
                    }

                    span.suggestions-text {
                        padding-top: $padding-width;
                    }
                }
            }

            .suggestions-container {
                &.multiple-columns,
                &.merged-column {
                    ul.suggestions-list-container {
                        span.suggestions-text,
                        li.suggestions-text,
                        li.top-search-query-text,
                        li a {
                            padding-top: $padding-width;
                            padding-bottom: $padding-width;
                            padding-left: $search-grid-side-padding;
                            padding-right: $search-grid-side-padding;
                        }
                    }
                }
            }
        }

        &.focus .new-suggestions {
            width: calc(100% + 2px);
            margin-left: -1px;
        }

        &.focus.show-suggestions .new-suggestions {
            width: calc(100% + 2px);
            margin-left: -1px;
            height: auto;
            transition: transform $search-grid-suggestions-transition-duration
                $search-grid-transition-timing $search-grid-border-transition-duration;
            transform: scaleY(1);
            z-index: 150; // scroll under mkt-headr, fas_sticky_header and over mkt-footr-scope
        }
    }
}

html.kiosk .scope-ssw .searchbox-grid.focus.show-suggestions .new-suggestions {
    max-height: fit-content;
}

html.desktop {
    .scope-ssw .searchbox-grid {
        &.focus.show-suggestions .new-suggestions {
            max-height: fit-content;
        }

        // needs to be 17px more than the maxWidthForMergedColumn value in setGridVisibilty()
        @media only screen and (max-width: 697px) {
            .new-suggestions {
                max-height: unset;
                @include narrow-grid-suggestions-merged-column-layout;

                .suggestions-container.merged-column
                    ul.suggestions-list-container
                    .article-column-container
                    .article-container
                    div.suggestion-item {
                    margin-left: 20px;
                    margin-right: 20px;

                    a .article-info .article-name {
                        width: calc(100vw - 235px);
                    }
                }
            }
        }
    }
}

// device specific override for mobile in portrait mode
html.mobileshop {
    @media (max-width: 680px) {
        .scope-ssw .searchbox-grid {
            .new-suggestions {
                @include narrow-grid-suggestions-merged-column-layout;

                .suggestions-container.merged-column
                    ul.suggestions-list-container
                    .article-column-container
                    .article-container {
                    $searchbox-padding: 40px;
                    $new-suggestions-padding: 32px;

                    div.suggestion-item a {
                        min-width: 290px;
                        width: calc(100vw - $searchbox-padding - $new-suggestions-padding);
                        padding-left: 20px;
                        padding-right: 20px;

                        .article-info .article-name {
                            width: calc(100vw - 200px);
                        }
                    }

                    &.compact {
                        @include compact-article-layout(
                            0px,
                            22px,
                            22px,
                            37px,
                            calc($searchbox-padding + $new-suggestions-padding)
                        );

                        div.result-button-container {
                            height: fit-content;
                            margin: 12px 0;

                            a.show-results-button {
                                margin-left: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// device specific override for mobile
html.mobileshop {
    @media (max-width: 767px) {
        .scope-ssw {
            .searchbox-grid {
                &.focus.show-suggestions .new-suggestions .list-container {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}

// include previous styles (without prices and images)
.scope-ssw {
    .searchbox-grid.legacy {
        .suggestions {
            width: calc(100% + 2px);
            min-height: 127px;
            margin-left: -1px;
            display: inherit;
            overflow: hidden;
            transition: transform $search-grid-suggestions-transition-duration
                $search-grid-transition-timing 0s;
            transform: scaleY(0);
            transform-origin: top;
            background-color: $color-e-s-white;
            text-align: left;
            position: relative;
            border-left: 1px solid $es-grey-border;
            border-right: 1px solid $es-grey-border;
            border-bottom: 1px solid $es-grey-border;
            border-bottom-left-radius: $search-grid-border-radius;
            border-bottom-right-radius: $search-grid-border-radius;
            z-index: 1046; // scroll under header and over footer
            html:not(.desktop) & {
                overflow-x: hidden;
                overflow-y: scroll;
            }

            ul {
                $padding-width: 11px;

                &.right {
                    width: 50%;
                }

                li {
                    &:first-child {
                        border-top: (10px + $padding-width) solid $color-e-s-white;
                    }

                    &:last-child {
                        margin-bottom: 28px;
                    }

                    &.suggestions-text,
                    &.top-search-query-text,
                    a {
                        padding-top: $padding-width;
                        padding-bottom: $padding-width;
                        padding-left: $search-grid-side-padding;
                        padding-right: $search-grid-side-padding;
                    }
                }
            }
        }

        &.focus .suggestions {
            width: calc(100% + 2px);
            margin-left: -1px;
        }

        &.focus.show-suggestions .suggestions {
            width: calc(100% + 2px);
            margin-left: -1px;
            height: auto;
            transition: transform $search-grid-suggestions-transition-duration
                $search-grid-transition-timing $search-grid-border-transition-duration;
            transform: scaleY(1);
            z-index: 1046; // scroll under header and over footer
        }
    }
}

.mobileshop {
    @media (max-width: 767px) {
        .scope-ssw {
            .searchbox-grid.legacy {
                &.focus.show-suggestions .suggestions .list-container ul li:first-child {
                    border-top: 0;
                }

                &.focus.show-suggestions .suggestions.multiple-lists .list-container {
                    display: flex;
                    flex-direction: column;

                    ul.left,
                    ul.right {
                        width: 100%;
                        float: none;
                        padding-right: 0;
                    }

                    ul.left li:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
