﻿@import "../styles.lib.scss";

$min-width-for-searchbox-wide: 992px;

.scope-ssw {
    .searchbox.wide {
        .new-suggestions {
            @include suggestion-wide-layout;
            $max-width-for-two-columns: 1349px; // should have same value as maxWidthForTwoColumns
            $left-suggestion-content-max-height: 918px; // should have same value as leftSuggestionContentMaxHeight
            $mkt-header-height: 50px;
            margin-left: -15px;

            .suggestions-container.multiple-columns .article-column-container.padded {
                padding-left: 16px;
            }

            .suggestions-container.multiple-columns
                .article-column-container
                .article-container
                div.suggestion-item.articles {
                border-bottom: 1px solid $lu21-color-e-s-mid-grey;

                // article left of the button
                &:nth-child(odd):nth-last-child(2) {
                    border-bottom: 0;
                }
                &:only-child {
                    border-bottom: 0;
                }
            }

            .suggestions-container.multiple-columns {
                &:has(> .suggestions-list-container):has(> .article-column-container) {
                    @media (max-width: $max-width-for-two-columns) {
                        .article-column-container {
                            &.padded {
                                padding-left: 10px;
                            }

                            .article-container {
                                flex-direction: column;

                                div.suggestion-item {
                                    margin-left: 0;

                                    &.hidden {
                                        display: none;
                                    }
                                }

                                .result-button-container {
                                    width: 290px;
                                    margin-left: 0;
                                }
                            }
                        }
                    }

                    @media (min-width: $min-width-for-searchbox-wide) and (max-width: $max-width-for-two-columns) {
                        .article-column-container .article-container {
                            width: 290px;
                        }
                    }
                }

                .article-column-container {
                    .article-container {
                        div.suggestion-item {
                            &:nth-child(even) {
                                margin-left: 10px;
                            }

                            a {
                                width: 295px;
                            }
                        }
                    }
                }

                &.desktop-scroll .suggestions-list-container {
                    // standard for wide screens
                    height: 516px; // use default height of article container
                    width: 305px;
                    overflow-y: auto;
                    scrollbar-width: thin;

                    // for narrow screens with less height
                    @media (max-width: $max-width-for-two-columns) and (max-height: 820px) {
                        height: auto;
                        width: 300px;
                    }

                    // for narrow screens with more height
                    @media (max-width: $max-width-for-two-columns) and (min-height: 821px) {
                        height: 738px; // use adjusted column height of article container
                    }
                }
            }

            &:has(> .suggestions-container.multiple-columns.desktop-scroll) {
                @media (max-width: $max-width-for-two-columns) and (max-height: calc($left-suggestion-content-max-height + $mkt-header-height)) {
                    max-height: calc(100vh - $mkt-header-height);
                    overflow-y: auto;
                    scrollbar-width: thin;
                }
            }

            &:has(> .suggestions-container.multiple-columns.kiosk-scroll) {
                max-height: calc(100vh - $mkt-header-height);
                overflow-y: auto;
                scrollbar-width: thin;
            }

            &:has(> .suggestions-container.multiple-columns.tablet-scroll) {
                max-height: calc(100vh - $mkt-header-height);
                overflow-y: auto;
                scrollbar-width: thin;
                right: 0;
            }
        }
    }
}

// device specific override for tablets in landscape mode
html.tablet {
    .scope-ssw .searchbox.wide {
        @media (min-width: 768px) {
            .new-suggestions {
                margin-left: -18px;
            }
        }
    }
}

// device specific override for desktop
html.desktop {
    .scope-ssw .searchbox.wide {
        @media (min-width: 1047px) {
            .new-suggestions {
                &:has(> .suggestions-container.multiple-columns.desktop-scroll) {
                    margin-left: -20px;
                }
            }
        }

        @media (min-width: $min-width-for-searchbox-wide) and (max-width: 1046px) {
            .new-suggestions {
                margin-left: -52px;

                &:has(.suggestions-container.multiple-columns
                        .suggestions-list-container:only-child) {
                    margin-left: -15px;
                }
            }
        }
    }
}

// include previous styles (without prices and images)
.scope-ssw {
    .searchbox.wide.legacy {
        .suggestions {
            @include suggestion-wide-medium-small-layout;
        }

        &.focus .suggestions {
            border-top: none;
        }
    }
}

// device specific override for suggestions with more than one column
.tablet {
    @media (min-width: 768px) {
        .scope-ssw .searchbox.wide.legacy .suggestions.multiple-lists {
            margin-left: -6vw;
        }
    }
}

.desktop {
    @media (min-width: 992px) and (max-width: 1067px) {
        .scope-ssw .searchbox.wide.legacy .suggestions.multiple-lists {
            margin-left: -6vw;
        }
    }
}
