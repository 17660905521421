@import "../styles.lib.scss";

$search-grid-button-left: 20px;
$search-grid-button-right: 6px;
$search-grid-button-width: 35px;

.scope-ssw {
    font-family: Roboto, Arial, Helvetica, sans-serif;

    &:not(.overlay) {
        transition: height $search-grid-suggestions-transition-duration
            $search-grid-transition-timing 0s;
        height: 70px;

        &.suggestions-visible {
            height: 750px;
        }
    }

    .searchbox-grid {
        text-align: center;
        margin: auto;
        width: calc(100% - 40px);
        max-width: 640px;
        border-radius: $search-grid-border-radius;
        border: 1px solid $search-grid-grey;
        height: 50px;
        background-color: $search-grid-grey;
        transition: border-radius $search-grid-border-transition-duration
                $search-grid-transition-timing $search-grid-suggestions-transition-duration,
            background-color 0s $search-grid-transition-timing 0s,
            border-color 0s $search-grid-transition-timing 0s;

        &.show-suggestions {
            transition: border-radius $search-grid-border-transition-duration
                    $search-grid-transition-timing $search-grid-suggestions-transition-duration,
                background-color 0s $search-grid-transition-timing
                    $search-grid-border-transition-duration +
                    $search-grid-suggestions-transition-duration,
                border-color 0s $search-grid-transition-timing
                    $search-grid-border-transition-duration +
                    $search-grid-suggestions-transition-duration,
                border-bottom-width 0s $search-grid-transition-timing
                    $search-grid-suggestions-transition-duration;

            .search-grid {
                .search-grid-button {
                    svg {
                        transition: fill 0s $search-grid-transition-timing
                                $search-grid-border-transition-duration +
                                $search-grid-suggestions-transition-duration,
                            stroke 0s $search-grid-transition-timing
                                $search-grid-border-transition-duration +
                                $search-grid-suggestions-transition-duration;
                    }
                }

                .transition-wrapper {
                    transition: transform $search-grid-border-transition-duration
                        $search-grid-transition-timing $search-grid-suggestions-transition-duration;

                    .input-sizer {
                        transition: transform $search-grid-border-transition-duration
                                $search-grid-transition-timing
                                $search-grid-suggestions-transition-duration,
                            padding-left $search-grid-border-transition-duration
                                $search-grid-transition-timing
                                $search-grid-suggestions-transition-duration;

                        .search-grid-input,
                        &::after {
                            transition: color 0s $search-grid-transition-timing
                                $search-grid-border-transition-duration +
                                $search-grid-suggestions-transition-duration;

                            &::placeholder {
                                transition: color 0s $search-grid-transition-timing
                                    $search-grid-border-transition-duration +
                                    $search-grid-suggestions-transition-duration;
                            }
                        }
                    }
                }
            }
        }

        .search-grid {
            position: relative;
            height: 50px;
            overflow: hidden;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .search-grid-button,
            .search-grid-input {
                vertical-align: top;
                height: 50px;
                background-color: transparent;
                border: none;
                outline: none;
                border-radius: 0;

                &::-ms-clear {
                    display: none;
                }
            }

            .search-grid-button {
                position: absolute;
                right: $search-grid-button-right;
                padding: 0;
                cursor: pointer;
                z-index: 1;

                svg {
                    width: $search-grid-button-width;

                    &.search-2021-icon {
                        display: inline;
                        height: 24px;
                    }
                }
            }

            .transition-wrapper {
                display: inline-block;
                text-align: left;
                width: 100%;
                height: inherit;
                transform: translateX(50%);
                transition: transform 0s $search-grid-transition-timing 0s;

                .input-sizer {
                    display: inline-grid;
                    height: 100%;
                    transform: translateX(-50%);
                    transition: transform 0s $search-grid-transition-timing 0s,
                        padding-left 0s $search-grid-transition-timing 0s;
                    padding-left: $search-grid-button-left + $search-grid-button-width;
                    padding-right: $search-grid-button-left + $search-grid-button-width;

                    .search-grid-input {
                        padding: 1px 2px;

                        &::placeholder {
                            color: $lu21-color-e-s-all-black;
                        }
                    }

                    .search-grid-input,
                    &::after {
                        width: 100%;
                        overflow: hidden;
                        grid-area: 1 / 2;
                        font-size: 17px;
                    }

                    &::after {
                        content: attr(data-value) " ";
                        visibility: hidden;
                        white-space: pre-wrap;
                    }
                }
            }
        }

        hr.grid-line {
            opacity: 0;
            margin-top: -2px;
            margin-right: $search-grid-side-padding;
            margin-bottom: 0;
            margin-left: $search-grid-side-padding;
            border: none;
            border-top: 1px solid $color-e-s-grey2;
            transition: opacity 0s $search-grid-transition-timing
                $search-grid-suggestions-transition-duration;
        }

        &.focus {
            background-color: $color-e-s-white;
            border-color: $color-e-s-grey2;
            border-radius: $search-grid-border-radius;
            transition: border-radius $search-grid-border-transition-duration
                    $search-grid-transition-timing $search-grid-suggestions-transition-duration,
                background-color 0s $search-grid-transition-timing 0s,
                border-color 0s $search-grid-transition-timing 0s,
                border-bottom-width 0s $search-grid-transition-timing
                    $search-grid-suggestions-transition-duration;

            .search-grid {
                .search-grid-button {
                    svg {
                        transition: fill 0s $search-grid-transition-timing 0s,
                            stroke 0s $search-grid-transition-timing 0s;
                    }
                }

                .transition-wrapper {
                    transform: translateX(0);
                    transition: transform 0s $search-grid-transition-timing 0s;

                    .input-sizer {
                        display: inline-block;
                        width: 100%;
                        transform: translateX(0);
                        transition: transform 0s $search-grid-transition-timing 0s,
                            padding-left 0s $search-grid-transition-timing 0s;
                        padding-left: $search-grid-side-padding;

                        .search-grid-input,
                        &::after {
                            color: $color-e-s-black;
                            transition: color 0s $search-grid-transition-timing 0s;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }

            &.show-suggestions {
                transition: border-radius $search-grid-border-transition-duration
                        $search-grid-transition-timing 0s,
                    border-bottom-width 0s $search-grid-transition-timing 0s;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-width: 0;
                border-bottom-color: $color-e-s-grey2;

                hr.grid-line {
                    opacity: 1;
                    transition: opacity $search-grid-border-transition-duration
                        $search-grid-transition-timing 0s;
                }
            }
        }
    }
}

// device specific override for desktop/kiosk: keeps suggestions from overlapping the footer
html.desktop,
html.kiosk {
    .scope-ssw:not(.overlay).suggestions-visible {
        // add whitespace to support the height for promotion tile + 10x TSQs + 7x content links
        height: 970px;
    }
}

// device specific override for mobile: keeps suggestions from overlapping the footer
html.mobileshop .scope-ssw:not(.overlay).suggestions-visible {
    height: 810px;
}

// specific override for Company/Surgery user in esApp: keeps app menu button from overlapping the suggestions
html.mobileshop.isEsApp
    .scope-ssw.suggestions-visible:has(.searchbox-grid.show-suggestions.esApp
        .suggestions-container.merged-column:not(.hidden)
        .article-container.compact) {
    height: 870px;
}
// specific override for Private users inesApp: keeps app menu button from overlapping the suggestions
html.mobileshop.isEsApp
    .scope-ssw.suggestions-visible:has(.searchbox-grid.show-suggestions.esApp
        .suggestions-container.merged-column:not(.hidden)
        .article-container.expanded) {
    height: 1288px;
}

// This is intermedia solution
// Since we do have view as query, use query should provide much precise result
// But that need to update consumer (WA/MA)
@media only screen and (min-width: 768px) {
    .scope-ssw {
        .searchbox-grid:hover:not(.focus) {
            background-color: $search-grid-hover-grey-background;

            .search-grid {
                .transition-wrapper {
                    .input-sizer {
                        .search-grid-input {
                            color: $search-grid-hover-grey;
                        }
                    }
                }
            }
        }
    }
}

// include previous styles (without prices and images)
.scope-ssw {
    .searchbox-grid.legacy {
        max-width: 750px;
    }
}
