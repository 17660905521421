﻿@import "../styles.lib.scss";

@mixin searchbox-flyout-layout {
    .search {
        position: relative;
        background-color: $color-e-s-white;

        form {
            width: inherit;

            .ssw-input,
            .ssw-button {
                background-color: $color-e-s-white;
                color: $lu21-color-e-s-all-black;

                svg {
                    display: inline;
                    fill: $lu21-color-e-s-all-black !important;
                    margin-top: 5px;

                    &:hover {
                        fill: $lu21-color-e-s-mid-grey;
                    }
                }
            }

            .ssw-input {
                width: calc(100% - 30px);
                height: 49px;
                text-align: left;
                text-transform: uppercase;
                padding: 2px 34px 2px 12px;
                margin: 0 15px;

                &:focus {
                    @include searchbox-flyout-layout-focus-input;
                }
            }

            .ssw-button {
                right: 15px;
            }
        }
    }

    &.focus .search form .ssw-input {
        @include searchbox-flyout-layout-focus-input;
    }
}

@mixin searchbox-flyout-layout-focus-input {
    text-transform: none;
}

@mixin searchbox-focus-input {
    background-color: transparent;
    color: $lu21-color-e-s-all-black;
    width: 300px;

    &::placeholder {
        color: transparent;
        background-color: transparent;
    }

    & + .ssw-button {
        background-color: transparent;
        color: $lu21-color-e-s-all-black;

        svg {
            fill: $lu21-color-e-s-all-black !important;
        }
    }
}

@mixin searchbox-wide-focus-input {
    background-color: transparent;
    outline: none;
    color: $lu21-color-e-s-all-black;

    & + .ssw-button svg {
        fill: $lu21-color-e-s-all-black !important;
    }
}
