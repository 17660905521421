@import "../styles.lib.scss";

.scope-ssw {
    .price-info {
        .strike-price {
            font-weight: bold;
            text-decoration: line-through;
        }

        .price {
            font-weight: bold;

            &.discounted {
                color: $lu21-color-e-s-red;
            }
        }

        .comparison-price {
            font-size: 10px;
            font-style: italic;
        }
    }

    .vat-info {
        font-size: 10px;
        color: $lu21-color-e-s-dark-grey;
    }
}

html.current-pricemode-net {
    .scope-ssw {
        .price-info,
        .vat-info {
            .net {
                display: inline-block;
            }

            .gross {
                display: none;
            }
        }
    }
}

html.current-pricemode-gross {
    .scope-ssw {
        .price-info,
        .vat-info {
            .net {
                display: none;
            }

            .gross {
                display: inline-block;
            }
        }
    }
}
